
#membershipContainer {
    flex-wrap: wrap;
}

#membershipContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px;
}

#membershipContainer h2 {
    font-size: 200%;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

#membershipContainer .membershipPrice {
    font-size: 200%;
    font-weight: bold;
    margin-top: auto;
}

#membershipContainer li {
    margin-bottom: 10px;
}

#membershipContainer ul {
    padding-left: 15px;
}