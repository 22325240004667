html, body {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./background.png"), linear-gradient(#333, #333);
  background-blend-mode: lighten;
  background-attachment: fixed;
  font-family: 'Roboto', serif;

}

* {
  box-sizing: border-box;
}

a, a:visited {
  color: green;
  text-decoration: none;
}
a:hover {
  color: lightgreen;
}

.header {
  background: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

div.header .logo {
  height: 50px;
  padding: 5px 20px;
}

#headerTools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
}

#main {
  box-sizing: border-box;
  margin: 10vh 3vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /*max-height: calc(75vh - 50px);*/
}



.maincontainer {
  height: 100vh;

}

.detailsselector {
  background: black;
  color: white;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.detailsselector > * {
  flex: 1;
  min-height: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.detailsselector > *:last-child {
  justify-content: flex-end;
}

.detailsselector > *:first-child {
  justify-content: flex-start;
}

.scrollhint.is-right-overflowing {
  background-image: linear-gradient(to left, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0));
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 50px 100%;
  background-attachment: scroll;
}

.scrollhint.is-left-overflowing {
  background-image: linear-gradient(to right, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0));
  background-position: 70px 0px;
  background-repeat: no-repeat;
  background-size: 50px 100%;
  background-attachment: scroll;
}
.scrollhint.is-left-overflowing.is-right-overflowing {
  background-image: linear-gradient(to left, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(0, 0, 20, .50), rgba(255, 255, 255, 0));
  background-position: right center, 70px 0px;
  background-repeat: no-repeat;
  background-size: 50px 100%, 50px 100%;
  background-attachment: scroll, scroll;

}


table.bookingtimes {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.bookingcontainer {
  width: 100%;
  overflow-x: auto;
}

table.bookingtimes tr td,
table.bookingtimes tr th {
  padding: 10px 10px;
  width: 140px;
}

table.bookingtimes td {
  white-space: nowrap;
}

table.bookingtimes span.slot {
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 3px;
  display: block;
  text-align: center;
  box-sizing: border-box;
  height: 42px;
}

table.bookingtimes td.selected span {
  border: 1px solid #90EE90;
  background-color: #e0ffe0;
}
table.bookingtimes td.selectable span {
  cursor: pointer;
}
table.bookingtimes td.removable span {
  cursor: pointer;
}

table.bookingtimes td.unselectable span {
  opacity: 30%;
}

table.bookingtimes td.event span.slot {
  cursor: pointer;
  background-color: #85cffd;
  padding: 0;
  font-size: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
table.bookingtimes td.event span.slotSubtext {
  display: block;
  font-size: 90%;
}

table.bookingtimes td.initial span {
  cursor: pointer;
}

table.bookingtimes th {
  white-space: nowrap;
}

table.bookingtimes .top-header {
  top: 0;
  position: sticky;
  text-align: center;
  font-weight: bold;
}

table.bookingtimes .side-header {
  position: sticky;
  left: 0;
  z-index: 100;
  background: white;
  width: 100px;
  height: 62px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}

table.bookingtimes .common-header {
  top: 0;
  left: 0;
  background: white;
  position: sticky;
  z-index: 101;
  width: 70px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::after {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
  position: absolute;
  top :50%;
  left: 50%;
  z-index: 10010;
}

.spin::before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #ffffffcc;
  content: "";
  z-index: 10000;
}

.spin {
  position: relative;
  cursor: wait;
}

.errorMessage {
  color: #ff5555;
  font-weight: bold;
  text-align: center;

  margin-bottom: 5px;
  font-size: 13px;
  min-height: 13px;
  display: block;
}

input.has-error {
  border: red solid 2px;
}


.button-action {
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}


.button-action:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-action {
  background-color: #2e46a4;
  border: 1px solid rgba(27, 31, 35, .15);
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  color: #fff;
}

.button-action:hover {
  background-color: #2c4597;
}

.button-action:focus {
  box-shadow: rgba(46, 70, 164, 0.4) 0 0 0 3px;
  outline: none;
}

.button-action:disabled {
  background-color: #949ed3;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-action:active {
  background-color: #29418e;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.button-confirm {
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  color: #fff;
}

.button-confirm:hover {
  background-color: #2c974b;
}

.button-confirm:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-confirm:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-confirm:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.button-cancel {
  background-color: #a42e2e;
  border: 1px solid rgba(27, 31, 35, .15);
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  color: #fff;
}

.button-cancel:hover {
  background-color: #972c2c;
}

.button-cancel:focus {
  box-shadow: rgba(164, 46, 46, 0.4) 0 0 0 3px;
  outline: none;
}

.button-cancel:disabled {
  background-color: #d39494;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-cancel:active {
  background-color: #8e2929;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

#currentDate {
  background: black;
  color: white;
  font-weight:  600;
  font-size: 120%;
  text-align: center;
  border: none;
  width: 220px;
}

.summaryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.summaryContainer > div {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex: 1;
  margin: 10px;
  padding: 20px 30px;
}

dl.summaryList {
  display: flex;
  flex-flow: row wrap;
}

dl.summaryList dt {
  flex-basis: 40%;
  padding: 2px 4px;
  text-align: left;
  font-weight: 600;
}

dl.summaryList dd {
  flex-basis: 60%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 4px;
}

ul.inline-list {
  list-style: none;
  padding: 0;
}
ul.inline-list li {
  display: inline;
}
ul.inline-list li:not(:first-child):before {
  content: ", "
}

.summaryContainer h2 {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.button-payment {
  display: block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#punchCards, .addons {
  list-style: none;
  padding: 0;
}
#punchCards li {
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #2e46a4;
  color: white;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
}

.addons li {
  display: block;
}

#punchCards label {
  cursor: pointer;
}

#punchCards .usedPunchCard {
  display: block;
  color: white;
  text-align: right;
  font-style: italic;
}

#bookings {
  list-style: none;
  display: block;
  padding: 0;
}

#bookings li {
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #2e46a4;
  color: white;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
}

#bookings li button {
  display: block;
  margin-top: 10px;
}

#payment-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
}

#datePicker .react-datepicker-wrapper {
  width: 220px;
}

#modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal {
  margin: auto;
  max-width: 500px;
  padding: 20px 40px;
  background: white;
  border-radius: 5px;
  position: relative;
  color: black;
}



.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.loginModal label, #discountCodeLabel, .addonLabel {
  display: block;
  background: #2e46a4;
  color: white;
  border-radius: 6px 6px 0px 0px;
  padding: 2px 5px;
  width: 40%;
  max-width: 250px;
  min-width: 150px;
}
.loginModal input, #discountCode, .addonSelect {
  display: inline-block;
  background-color: white;
  border-radius: 0px 6px 6px 6px;
  font-size: 14px;
  padding: 2px 5px;
  border-color: #2e46a4;
  border-width: 2px;
}

.loginModal input.has-error {
  border: red solid 2px;
}


#discountCode, .addonSelect {
  width: 100%;
}

.loginModal input:focus {
  outline: none;
}

.loginModal button {
  margin-top: 10px;
}

.close-button {
  position: absolute;
  right: 20px;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  border: 2px solid black;
  color: black;
  font-size: 25px;
  line-height: 10px;
  background: white;
  cursor: pointer;
}
.close-button:hover {
  border: 2px solid red;
  color: red;
}

.buttonActions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.bottomDetails {
  padding: 10px 20px;
}

/****/

@media screen and (max-width: 1000px) {
  #main {
    margin: 0;
  }

  .summaryContainer {
    flex-direction: column;
  }

  .moveLastMobile {
    order: 999;
  }

  .detailsselector {
    flex-direction: column;
    align-items: stretch;
  }
  .detailsselector > *, .detailsselector > *:last-child, .detailsselector > *:first-child {
    justify-content: center;
    text-align: center;
  }

  .modal {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    padding: 10px 10px;
  }

  .summaryContainer > div {
    margin: 10px;
    padding: 10px;
  }

  .buttonActions {
    flex-direction: column;
  }

  .button-book, .button-today {
    display: none;
  }
}